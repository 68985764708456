
import { defineComponent, onMounted, ref } from "vue";
import fetchData from "@/utils/axios";
import Jumbortron from "@/components/template/blocks/Jumbortron.vue";
import Title from "@/components/atoms/Title.vue";
import Container from "@/components/layouts/Container.vue";
import Text from "@/components/atoms/Text.vue";

export default defineComponent({
  name: "Jobs",
  components: { Text, Container, Title, Jumbortron },
  setup() {
    const backendUrl = process.env.VUE_APP_BACKEND_URL;
    const data = ref(null);
    const jobs = ref(null);
    const error = ref(null);

    onMounted(async () => {
      try {
        const res = await fetchData("/api/pages", {
          filters: {
            Title: {
              $eq: "Jobs",
            },
          },
          populate: {
            Hero: {
              populate: "*",
            },
          },
        });

        data.value = res.data.data[0].attributes;

        const jobsRes = await fetchData("/api/jobs", {});
        jobs.value = jobsRes.data.data;
      } catch (err) {
        error.value = err;
      }
    });

    return { backendUrl, data, error, jobs };
  },
});
