import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "jobs" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Jumbortron = _resolveComponent("Jumbortron")!
  const _component_Title = _resolveComponent("Title")!
  const _component_VueShowdown = _resolveComponent("VueShowdown")!
  const _component_Text = _resolveComponent("Text")!
  const _component_Container = _resolveComponent("Container")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Jumbortron, {
      title: _ctx.data?.Hero?.Title,
      full: true,
      background: 
        _ctx.data ? _ctx.backendUrl + _ctx.data?.Hero?.Background.data.attributes.url : ''
      ,
      scrollTo: {
        id: 'next',
        img: _ctx.data ? _ctx.backendUrl + _ctx.data?.Hero?.Arrow.data.attributes.url : '',
      }
    }, null, 8, ["title", "background", "scrollTo"]),
    _createVNode(_component_Container, {
      size: "medium",
      id: "next"
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.jobs, (job, index) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "job",
            key: index
          }, [
            _createVNode(_component_Title, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(job.attributes.title), 1)
              ]),
              _: 2
            }, 1024),
            _createVNode(_component_Text, { class: "markdown" }, {
              default: _withCtx(() => [
                _createVNode(_component_VueShowdown, {
                  markdown: job.attributes.content,
                  flavor: "github"
                }, null, 8, ["markdown"])
              ]),
              _: 2
            }, 1024)
          ]))
        }), 128))
      ]),
      _: 1
    })
  ]))
}